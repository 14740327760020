<template>
  <svg viewBox="0 0 16 16">
    <path
      d="M4.276 10.6674L11.0373 3.90604L10.0947 2.96337L3.33333 9.72471V10.6674H4.276ZM4.82867 12.0007H2V9.17204L9.62333 1.54871C9.74835 1.42373 9.91789 1.35352 10.0947 1.35352C10.2714 1.35352 10.441 1.42373 10.566 1.54871L12.452 3.43471C12.577 3.55973 12.6472 3.72926 12.6472 3.90604C12.6472 4.08282 12.577 4.25236 12.452 4.37737L4.82867 12.0007V12.0007ZM2 13.334H14V14.6674H2V13.334Z"
    />
  </svg>
</template>

<script>
export default {
  name: "EditIcon",
};
</script>
